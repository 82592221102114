export class Env {
  _id: string;
  name: string;
  vaultKey: string;

  constructor(data?: any) {
    data = data || {};
    this._id = data._id;
    this.name = data.name;
    this.vaultKey = data.vaultKey;
  }

}
