import {Component, Injectable, Type} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SidebarFormService {
  public toggleForm: Subject<{state: boolean, component?: any, data?: any, index?: number}>
  public onFormClosed: Subject<any>;
  constructor() {
    this.toggleForm = new Subject<{state: boolean, component?: any, data?: any, index?: number}>();
    this.onFormClosed = new Subject();
  }

  openForm(component: any, data?: any, index?: number) {
    this.toggleForm.next({state: true, component: component, data: data, index: index})
  }

  closeForm(data?: any) {
    this.toggleForm.next({state: false})
    if(data) {
      return this.onFormClosed.next(data);
    }
  }
}
