import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpInterceptor,
  HttpHandler,
  HttpClient
} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {finalize, switchMap} from 'rxjs/operators';
import {environment} from "../environments/environment";
import {NgxUiLoaderService} from "ngx-ui-loader";

@Injectable()
export class AppInterceptorService implements HttpInterceptor {
  private readonly withoutTenantId: string [];

  constructor(
    private http: HttpClient,
    private loaderService: NgxUiLoaderService
  ) {
    this.withoutTenantId = environment.interceptor.withoutTenantId;
  }

  private shouldContainTenantId(url: string): boolean {
    return this.withoutTenantId.indexOf(url) === -1;
  }

   intercept(request: HttpRequest<any>, next: HttpHandler): any {
     this.loaderService.start()
     return  this.addTenantIdToHeader(request, '62f10f31e1fb3afd404edbd6').pipe(
        switchMap((req) => this.addTeamIdToHeader(req)),
        switchMap((req: HttpRequest<any>) => next.handle(req)),
        finalize(() => this.loaderService.stop()));
  }

  addTenantIdToHeader(request: HttpRequest<any>, id: string | null): Observable<HttpRequest<any>> {
    let requestToHandle;
    if(this.shouldContainTenantId(request.url)) {
      requestToHandle = request.clone({ headers: request.headers
        .set('x-tenant-id', `${id}`)
      });
    }else {
      requestToHandle = request;
    }
    return  of(requestToHandle);
  }

  addTeamIdToHeader(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    if(request?.body?.teamId) {
    }
    return of(request)
  }

}
