<div class="galaxy-container">
</div>
<img class="header-logo" src="assets/SVG/Fichier%203.svg" alt="logo">
<div class="home-content">

  <div class="block" style="position: relative">
    <img src="assets/bg-stars-1.webp" style="position: absolute;
    top: -10rem;
    left: -30rem;
    height: auto !important;
    width: 91.66666663%;
    transform: translateX(300px) rotateZ(180deg);" alt="stars">
    <div class="time-line-container">
      <!--<div style="background: linear-gradient(#c0f, #E63FFF 10%, #F766FF 70%, #FF99FC 80%, #FFCCFA);" class="time-line"></div>-->
      <img src="assets/SVG/Fichier%201.svg" alt="logo" class="release-management">
    </div>
    <div class="time-line-text">
      <h2 style="color: #F766FF">Focus on writing code.
        <span style="color: #fff">
        We'll take care of the rest
      </span>
      </h2>
      <div style="display: flex">
        <div style="display: flex;
                    flex-direction: column;
                    padding-right: 3rem;
                    padding-left: 3rem;
                    width: 50%;
                    text-align: justify;">
          <p style="color: #8b949e; font-size: 20px !important; line-height: 28px !important;">
            Generate and manage the structure of your projects.
          </p>
          <p style="color: #8b949e; font-size: 20px !important; line-height: 28px !important;">
            Automate the building and deployment process using built-in <span style="color: #fff">GitHub Actions.</span>
          </p>
          <p style="color: #8b949e; font-size: 20px !important; line-height: 28px !important;">
            Automate release management.
          </p>
          <p style="color: #8b949e; font-size: 20px !important; line-height: 28px !important;">
            Automate repetitive tasks and <span style="color: #fff">allow developers to focus on writing code.</span>
          </p>
          <p style="color: #8b949e; font-size: 20px !important; line-height: 28px !important;">
            Easily manage <span style="color: #fff">multiple environments</span> such as development, staging, and production.
          </p>
        </div>
        <div style="display: flex; position: relative; width: 50%">
          <app-ci></app-ci>
          <app-spring-file></app-spring-file>
          <app-create-application style="position: absolute; top: 8rem; right: -12rem"></app-create-application>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 100px; height: 100px"></div>

  <div class="block" style="margin-top: 8rem; position: relative">
    <img src="assets/bg-stars-1.webp" style="position: absolute;
    top: -14rem;
    right: 0;
    height: 95rem !important;
    width: 91.66666663%;
    transform: translateX(300px) rotateZ(180deg);" alt="stars">
    <div class="time-line-container">
      <div
        style="background: linear-gradient(#0284FF, #41ACFF 10%, #67C4FF 70%, #99DDFF 80%, #CCF0FF);"
        class="time-line">
      </div>
    </div>
    <div class="time-line-text" style="height: 29rem">
      <h2 style="color: #41ACFF;margin-bottom: 4rem;">Reduce time to market.
        <span style="color: #fff">
        Streamline your software development process. Speed up the release of new features and bug fixes
        </span>
      </h2>

      <div class="slider">
        <div class="slide-track">
          <ng-container *ngFor="let r of repeat">
            <div class="slide">
              <div class="steps-container">
                <div>
                  <svg class="machine"xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 645 526"  >
                    <defs/>
                    <g>
                      <path  x="-173,694" y="-173,694" class="large-shadow" d="M645 194v-21l-29-4c-1-10-3-19-6-28l25-14 -8-19 -28 7c-5-8-10-16-16-24L602 68l-15-15 -23 17c-7-6-15-11-24-16l7-28 -19-8 -14 25c-9-3-18-5-28-6L482 10h-21l-4 29c-10 1-19 3-28 6l-14-25 -19 8 7 28c-8 5-16 10-24 16l-23-17L341 68l17 23c-6 7-11 15-16 24l-28-7 -8 19 25 14c-3 9-5 18-6 28l-29 4v21l29 4c1 10 3 19 6 28l-25 14 8 19 28-7c5 8 10 16 16 24l-17 23 15 15 23-17c7 6 15 11 24 16l-7 28 19 8 14-25c9 3 18 5 28 6l4 29h21l4-29c10-1 19-3 28-6l14 25 19-8 -7-28c8-5 16-10 24-16l23 17 15-15 -17-23c6-7 11-15 16-24l28 7 8-19 -25-14c3-9 5-18 6-28L645 194zM471 294c-61 0-110-49-110-110S411 74 471 74s110 49 110 110S532 294 471 294z"/>
                    </g>
                    <g>
                      <path x="-136,996" y="-136,996" class="medium-shadow" d="M402 400v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L352 323c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L402 400zM265 463c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C338 430 305 463 265 463z"/>
                    </g>
                    <g >
                      <path x="-100,136" y="-100,136" class="small-shadow" d="M210 246v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H100l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L10 225v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L210 246zM110 272c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S131 272 110 272z"/>
                    </g>
                    <g>
                      <path x="-100,136" y="-100,136" class="small" d="M200 236v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H90l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L0 215v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L200 236zM100 262c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S121 262 100 262z"/>
                    </g>
                    <g>
                      <path x="-173,694" y="-173,694" class="large" d="M635 184v-21l-29-4c-1-10-3-19-6-28l25-14 -8-19 -28 7c-5-8-10-16-16-24L592 58l-15-15 -23 17c-7-6-15-11-24-16l7-28 -19-8 -14 25c-9-3-18-5-28-6L472 0h-21l-4 29c-10 1-19 3-28 6L405 9l-19 8 7 28c-8 5-16 10-24 16l-23-17L331 58l17 23c-6 7-11 15-16 24l-28-7 -8 19 25 14c-3 9-5 18-6 28l-29 4v21l29 4c1 10 3 19 6 28l-25 14 8 19 28-7c5 8 10 16 16 24l-17 23 15 15 23-17c7 6 15 11 24 16l-7 28 19 8 14-25c9 3 18 5 28 6l4 29h21l4-29c10-1 19-3 28-6l14 25 19-8 -7-28c8-5 16-10 24-16l23 17 15-15 -17-23c6-7 11-15 16-24l28 7 8-19 -25-14c3-9 5-18 6-28L635 184zM461 284c-61 0-110-49-110-110S401 64 461 64s110 49 110 110S522 284 461 284z"/>
                    </g>
                    <g>
                      <path x="-136,996" y="-136,996" class="medium" d="M392 390v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L342 313c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L392 390zM255 453c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C328 420 295 453 255 453z"/>
                    </g>
                  </svg>

                </div>
                <p>Create your application</p>
                <div class="right-external-round">
                </div>
                <div class="right-internal-round">
                </div>
                <div class="arrow">
                </div>
              </div>
            </div>
            <div class="slide">
              <div class="steps-container">
                <img src="assets/SVG/k8s.svg" alt="k8s" class="jump">
                <p>Create an environment</p>
                <div class="left-external-round">
                </div>
                <div class="left-internal-round">
                </div>
                <div class="right-external-round">
                </div>
                <div class="right-internal-round">
                </div>
                <div class="arrow">
                </div>

              </div>
            </div>
            <div class="slide">
              <div class="steps-container">
                <img src="assets/SVG/vault.svg" alt="vault" class="jump">
                <p>Configure your environment secrets</p>
                <div class="left-external-round">
                </div>
                <div class="left-internal-round">
                </div>
                <div class="right-external-round">
                </div>
                <div class="right-internal-round">
                </div>
                <div class="arrow">
                </div>
              </div>
            </div>
            <div class="slide">
              <div class="steps-container">
                <img src="assets/SVG/github.svg" alt="github" class="jump">
                <p>Push your code</p>
                <div class="left-external-round">
                </div>
                <div class="left-internal-round">
                </div>
                <div class="right-external-round">
                </div>
                <div class="right-internal-round">
                </div>
                <div class="arrow">
                </div>
              </div>
            </div>
            <div class="slide">
              <div class="steps-container">
                <img src="assets/SVG/rocket.svg" alt="rocket">
                <p>You app is deployed</p>
                <div class="left-external-round">
                </div>
                <div class="left-internal-round">
                </div>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; flex-direction: row">
      <app-environment-steps></app-environment-steps>
  </div>
  <div style="width: 100px; height: 100px"></div>

  <div class="block" style="margin-top: 8rem; position: relative">
    <img src="assets/bg-stars-1.webp" style="position: absolute;
    top: -6rem;
    right: 0;
    height: auto !important;
    width: 91.66666663%;
    transform: translateX(300px) rotateZ(180deg);" alt="stars">
    <div class="time-line-container">
      <div
        style="background: linear-gradient(#FFA28B, #FFBFA8 10%, #FFD1B9 70%, #FFE4D0 80%, #FFF3E7);"
        class="time-line">
      </div>
    </div>
    <div class="time-line-text">
      <h2 style="color: #ffa28b;">Easily manage your environments.
        <span style="color: #fff">
        An intuitive platform that allows you to secure your configurations, deploy, and roll back your versions with 0 code.
        </span>
      </h2>
    </div>
  </div>
  <div style="position:relative; margin-bottom: 18rem;">
    <img src="assets/bg-stars-1.webp" style="position: absolute;
    top: -24rem;
    right: 0;
    height: auto !important;
    width: 91.66666663%;
    transform: translateX(300px) rotateZ(180deg);" alt="stars">
      <app-workflow-run></app-workflow-run>
      <div style="top: 160px;
        animation : border-animation 2s ease-in-out both infinite;
        left: 25%;position: absolute; width: 20rem; height: 8rem; border-radius: 0 0 0 8px"></div>
      <app-environment style="position: absolute; left: 50%"></app-environment>
  </div>
  <div>

  </div>
  <div style="width: 100px; height: 100px"></div>

  <div class="block" style="margin-top: 8rem">
    <div class="time-line-container">
      <div
        style="background: linear-gradient(#FC0, #FFF099 10%, #FFE666 70%, #FFF099 80%, #FFF9CC);"
        class="time-line"></div>
    </div>
    <div class="time-line-text">
      <h2 style="color: #FFDC3F">A complete ecosystem
        <span style="color: #fff">
        for managing the lifecycle of your application
      </span>
      </h2>
    </div>
  </div>


</div>

<div>

</div>

<div style="display: flex; justify-content: center; margin-bottom: 18rem;">
  <app-nephos-galaxy></app-nephos-galaxy>
</div>


