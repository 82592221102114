import {TechnologiesEnum} from "./technologies.enum";

export class TechnologyDefinition {
  name: string;
  availableVersions: string[];
  friendlyName: string;
  constructor(data?: any) {
    data = data || {};
    this.name = data.name;
    this.availableVersions = data.availableVersions;
    this.friendlyName = TechnologiesEnum[this.name as keyof typeof TechnologiesEnum]
  }

}
