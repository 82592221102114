<nb-card>
  <nb-card-header style="display: flex;flex-direction: row;align-items: center;">
    <nb-icon icon="checkmark-circle-2-outline"  [status]="'success'"></nb-icon>
    <div style="margin-left: 12px; color: #41ACFF">
      {{getPropByString(environment, 'name')}}
    </div>
    <div style="margin-left: 12px">
      <nb-select [(ngModel)]="selectedTag">
        <nb-option *ngFor="let tag of environment?.artifact?.tags" [value]="tag">{{tag}}</nb-option>
      </nb-select>
    </div>
    <div style="margin-left: 12px">
      <button nbButton status="primary">{{'environment.form.deploy' | translate}}</button>
    </div>
  </nb-card-header>
  <nb-list>
    <nb-list-item *ngIf="getImageTag()">
      <span class="title">{{'environment.form.currentVersion' | translate | titlecase}}: </span>
      <nb-tag appearance="outline" style="text-transform: lowercase" [text]="getImageTag()" status="primary">
      </nb-tag>
    </nb-list-item>
    <nb-list-item>
      <span class="title">{{'environment.properties.application' | translate | titlecase}}:</span>
      <nb-tag appearance="outline" style="text-transform: lowercase" [text]="environment?.application?.name" status="primary">
      </nb-tag>
    </nb-list-item>
    <nb-list-item>
      <span class="title">{{'environment.properties.env' | translate | titlecase}}:</span>
      <nb-tag appearance="outline" style="text-transform: lowercase" [text]="getPropByString(environment, 'env')" status="primary">
      </nb-tag>
    </nb-list-item>
  </nb-list>
</nb-card>
