import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  NbSidebarModule,
  NbDialogModule,
  NbToastrModule,
  NbCardModule,
  NbMenuModule,
  NbButtonModule,
  NbIconModule,
  NbPopoverModule,
  NbSpinnerModule, NbSelectModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {AdDirective} from "./sidebar/ad.directive";
import {TitleCasePipe} from "@angular/common";
import {NotificationService} from "./notification.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LoaderService} from "./loader.service";
import {AppInterceptorService} from "./app-interceptor.service";
import {AuthHttpInterceptor, AuthModule} from "@auth0/auth0-angular";
import {ConfigService} from "./config.service";
import {NgxUiLoaderModule} from "ngx-ui-loader";
import {HomeModule} from "./home/home.module";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    AdDirective,
  ],
  imports: [
    AuthModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({name: 'dark'}),
    HomeModule,
    NbLayoutModule,
    NbEvaIconsModule,
    NbSidebarModule.forRoot(),
    NbLayoutModule,
    NbEvaIconsModule,
    NbIconModule,
    NbButtonModule,
    NbMenuModule.forRoot(),
    NbCardModule,
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NbPopoverModule,
    NbSpinnerModule,
    NgxUiLoaderModule,
    NbSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    HttpClientModule
  ],
  providers: [
    TranslateService,
    TitleCasePipe,
    NotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptorService, multi: true },
    LoaderService,
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        return new Promise((resolve) => {
          resolve(resolve)
        });
      },
      deps: [ ConfigService ],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}


