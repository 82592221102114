import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-spring-file',
  templateUrl: './spring-file.component.html',
  styleUrls: ['./spring-file.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpringFileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
