import {Component, Inject, ViewChild, ViewEncapsulation} from '@angular/core';
import {NbMenuItem, NbSidebarService, NbThemeService} from "@nebular/theme";
import {lastValueFrom} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {DOCUMENT, TitleCasePipe} from "@angular/common";
import {SidebarFormService} from "./sidebar/sidebar-form.service";
import {AdDirective} from "./sidebar/ad.directive";
import {AuthService} from "@auth0/auth0-angular";
import {NgxUiLoaderService} from "ngx-ui-loader";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  @ViewChild(AdDirective, {static: true}) adHost!: AdDirective;
  isLoading = false;
  menuItems: NbMenuItem [] = [];
  public theme = 'dark'
  public static CurrentTheme = 'dark'
  constructor(
    private sidebarService: NbSidebarService,
    private translateService: TranslateService,
    private titleCasePipe: TitleCasePipe,
    public sidebarFormService: SidebarFormService,
    public auth: AuthService,
    public themeService: NbThemeService,
    private translate: TranslateService,
    @Inject(DOCUMENT) public document: Document
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  async themeChange(theme: any) {
    AppComponent.CurrentTheme = theme;
    this.themeService.changeTheme(theme);
  }

  async login() {
    this.auth.loginWithRedirect({
      appState: {
        target: '/applications'
      }
    })
  }

  async ngOnInit() {
    this.menuItems = [
      {
        title: this.titleCasePipe
        .transform(await lastValueFrom(this.translateService.get('menu.applications'))),
        link: '/applications'
      },
      {
        title: this.titleCasePipe
        .transform(await lastValueFrom(this.translateService.get('menu.environments'))),
        link: '/environments'
      },
      {
        title: this.titleCasePipe
        .transform(await lastValueFrom(this.translateService.get('menu.teams'))),
        link: '/teams'
      },
      {
        title: this.titleCasePipe
        .transform(await lastValueFrom(this.translateService.get('menu.members'))),
        link: '/members'
      }
    ]
    this.sidebarFormService.toggleForm.subscribe(
      (toggleForm => {
        if(toggleForm.state) {
          const viewContainerRef = this.adHost.viewContainerRef;
          viewContainerRef.clear();
          const componentRef = viewContainerRef.createComponent<any>(toggleForm.component);
          componentRef.instance.data = {item: toggleForm.data, index: toggleForm.index };
          this.sidebarService.expand('right');
        } else {
          this.sidebarService.collapse('right');
        }
      })
    )

  }

  async toggleLeftSideLeftSideNav() {
    this.sidebarService.toggle(false, 'left');
  }

  async logout() {
    this.isLoading = true
    this.auth.logout()
  }


}



