import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "@auth0/auth0-angular";
import {HomeComponent} from "./home/home.component";
const routes: Routes = [
  {
    title: 'applications',
    path: 'applications',
    loadChildren: () => import('./application/application.module').then( m => m.ApplicationModule),
    canActivate: [AuthGuard],

  },
  {
    path: '',
    component: HomeComponent
  },
  {
    title: 'environments',
    path: 'environments',
    loadChildren: () => import('./environment/environment.module').then( m => m.EnvironmentModule),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    title: 'teams',
    path: 'teams',
    loadChildren: () => import('./team/team.module').then( m => m.TeamModule),
    canActivate: [AuthGuard],
  },
  {
    title: 'members',
    path: 'members',
    loadChildren: () => import('./member/member.module').then( m => m.MemberModule),
    canActivate: [AuthGuard],
  },

  {
    title: 'default',
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules
    }
  )],
  exports: [RouterModule],
})
export class AppRoutingModule { }
