import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-environment-steps',
  templateUrl: './environment-steps.component.html',
  styleUrls: ['./environment-steps.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
              style({ height: 65, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 65, opacity: 1 }),
            animate('1s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]

})
export class EnvironmentStepsComponent implements OnInit {
  steps: any = [];
  stepsTPL: any [] = [
    {
      "name": "Create environment on database",
      "errors": [],
      "status": "success"
    },
    {
      "name": "Update github workflow (activate new environment deploy)",
      "errors": [],
      "status": "success"
    },
    {
      "name": "Create spring cloud config to connect to vault",
      "errors": [],
      "status": "success"
    },
    {
      "name": "Create vault secret directory",
      "errors": [],
      "status": "success"
    },
    {
      "name": "Create environment directory and manifests on manifests git repository",
      "errors": [],
      "status": "success"
    },
    {
      "name": "Generate manifests repo ssh keypair and create argocd repository",
      "errors": [],
      "status": "success"
    },
    {
      "name": "Create argocd project",
      "errors": [],
      "status": "success"
    },
    {
      "name": "Create argocd application",
      "errors": [],
      "status": "success"
    }
  ];
  constructor() { }

  ngOnInit(): void {
    this.start();
  }
  start() {
    let i = 0
    const interval = setInterval(() => {
      if(i === this.stepsTPL.length -1) {
        clearInterval(interval)
        this.steps = [];
        this.start();
      }
      this.steps.push(this.stepsTPL[i])
      i++;
    }, 1000)

  }

}
