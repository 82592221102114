import {Inject, Injectable, InjectionToken} from '@angular/core';
import {AuthClientConfig} from "@auth0/auth0-angular";

export const APP_CONFIG: InjectionToken<Configuration>
  = new InjectionToken<Configuration>('Application Configuration');

export interface Configuration {
  production: boolean;
  interceptor: Interceptor;
  auth0: Auth0;
  socket: SocketConfig
}

export class SocketConfig {
  application: string;
  environment: string
}
interface  Interceptor {
  excludedUrls: string [];
}

interface Auth0 {
  domain: string;
  clientId: string;
  cacheLocation: any;
  useRefreshTokens: boolean;
  useRefreshTokensFallback: boolean;
  authorizationParams:{
    redirect_uri: string,
    audience: string
  },
  httpInterceptor: {
    allowedList: {
      tokenOptions: {
        authorizationParams: {
          audience: string
        }
      }
      uri: string,
    }[]
  }
}


@Injectable({providedIn: 'root'})
export class ConfigService {

  public static configuration$: Configuration;

  constructor(
    @Inject(APP_CONFIG) private readonly appConfig: any,
    private authClientConfig: AuthClientConfig,
  ) {
    this.authClientConfig = authClientConfig;
    ConfigService.configuration$ = <Configuration>appConfig;
    this.authClientConfig.set({
      domain: ConfigService.configuration$.auth0.domain,
      clientId: ConfigService.configuration$.auth0.clientId,
      cacheLocation: ConfigService.configuration$.auth0.cacheLocation,
      useRefreshTokens: ConfigService.configuration$.auth0.useRefreshTokens,
      useRefreshTokensFallback: ConfigService.configuration$.auth0.useRefreshTokensFallback,
      authorizationParams: ConfigService.configuration$.auth0.authorizationParams,
      httpInterceptor: ConfigService.configuration$.auth0.httpInterceptor
    });

  }

}
