import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HomeComponent} from "./home.component";
import {HomeRoutingModule} from "./home-routing.module";
import { CiComponent } from './components/ci/ci.component';
import { SpringFileComponent } from './components/spring-file/spring-file.component';
import { NephosGalaxyComponent } from './components/nephos-galaxy/nephos-galaxy.component';
import { CreateApplicationComponent } from './components/create-application/create-application.component';
import {
  NbAccordionModule,
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbInputModule, NbListModule,
  NbSelectModule, NbSpinnerModule, NbTagModule
} from "@nebular/theme";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import { EnvironmentComponent } from './components/environment/environment.component';
import { WorkflowRunComponent } from './components/workflow-run/workflow-run.component';
import { EnvironmentStepsComponent } from './components/environment-steps/environment-steps.component';




@NgModule({
  declarations: [
    HomeComponent,
    CiComponent,
    SpringFileComponent,
    NephosGalaxyComponent,
    CreateApplicationComponent,
    EnvironmentComponent,
    WorkflowRunComponent,
    EnvironmentStepsComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    NbCardModule,
    ReactiveFormsModule,
    TranslateModule,
    NbSelectModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule,
    FormsModule,
    NbListModule,
    NbTagModule,
    NbAccordionModule,
    NbSpinnerModule
  ],
  providers: [
  ]
})
export class HomeModule { }
