export class Application {
  _id?: string;
  name: string;
  description: string;
  technology: Technology;
  status: string;
  applicationCreationSteps?: ApplicationCreationSteps;
  sourcesRepository: string;
  manifestsRepository: string;
  teamId: string;
  constructor(data?: any) {
    data = data || {};
    this._id = data._id;
    this.name = data.name;
    this.description = data.description;
    this.technology = data.technology;
    this.status = data.status;
    this.applicationCreationSteps = new ApplicationCreationSteps(data?.applicationCreationSteps);
    this.sourcesRepository = data.sourcesRepository;
    this.manifestsRepository = data.manifestsRepository;
    this.teamId = data.teamId;
  }
}


export class Technology {
  name: string;
  version: string;
  constructor(data?: any) {
    data = data || {};
    this.name = data.name;
    this.version = data.version
  }
}
export class CardItem {
  descriptionMode?: boolean;
  linkMode?: boolean;
  isTechnology?: boolean
  title: string;
  property: string;

  constructor(data?: any) {
    data = data || {};
    this.title = data.title || '';
    this.property = data.detail || '';
    this.descriptionMode  = data.descriptionMode || false;
    this.linkMode = data.linkMode || false;
    this.isTechnology = data.isTechnology
  }
}


export class ApplicationCreationSteps {
  createSourcesRepository: Step;
  addGhTokenSecret: Step;
  addRegistryPasswordSecret: Step;
  addDockerfile: Step;
  addEntrypoint: Step
  addNginxConf: Step
  addSnapshotWorkflow: Step;
  addReleaseWorkflow: Step
  createManifestsRepository: Step;
  addReadme: Step;

  constructor(data?: any) {
    data = data || {};
    this.createSourcesRepository = new Step(data?.createSourcesRepository)
    this.addGhTokenSecret = new Step(data?.addGhTokenSecret)
    this.addRegistryPasswordSecret = new Step(data?.addRegistryPasswordSecret)
    this.addDockerfile = new Step(data?.addDockerfile)
    this.addEntrypoint = new Step(data?.addEntrypoint)
    this.addNginxConf = new Step(data?.addNginxConf)
    this.addSnapshotWorkflow = new Step(data?.addSnapshotWorkflow)
    this.addReleaseWorkflow = new Step(data?.addReleaseWorkflow)
    this.createManifestsRepository = new Step(data?.createManifestsRepository)
    this.addReadme = new Step(data?.addReadme)
  }
}

export class Step {
  name: string;
  errors: string []
  order: number;

  constructor(data?: any) {
    this.name = data?.name;
    this.errors = data?.errors;
    this.order = data?.order;
  }
}


