<nb-card>
  <nb-card-header>
    <div style="display: flex; flex-direction: row">
      <div>
        {{'environment.form.creationSteps' | translate | uppercase}}
      </div>
    </div>
  </nb-card-header>
  <nb-accordion>
    <nb-accordion-item *ngFor="let step of steps;" [disabled]="step?.errors?.length <= 0"   [@inOutAnimation]>
      <nb-accordion-item-header>
        <nb-icon icon="checkmark-circle-2-outline" *ngIf="step?.status === 'success'" [status]="'success'"></nb-icon>
        <nb-icon icon="slash-outline"  *ngIf="step?.status === 'basic'" [status]="'basic'"></nb-icon>
        <nb-icon icon="close-circle-outline" *ngIf="step?.status === 'danger'"  [status]="'danger'"></nb-icon>

        <span style="margin-left: 8px;">
        {{step?.name}}
      </span>


      </nb-accordion-item-header>
    </nb-accordion-item>
  </nb-accordion>
</nb-card>
