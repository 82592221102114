<div id="rotator">
  <div class="github">
    <img src="assets/SVG/github.svg" alt="github" style="width: 4rem">
  </div>
  <div class="k8s">
    <img src="assets/SVG/k8s.svg" alt="k8s" style="width: 4rem;">
  </div>
  <div class="argocd">
    <img src="assets/SVG/argocd.svg" alt="argocd" style="width: 4rem;">
  </div>
  <div class="vault">
    <img src="assets/SVG/vault.svg" alt="vault" style="width: 4rem">
  </div>
  <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; animation: inherit; animation-direction: reverse;">
    <h2 style="margin-left: 2rem; color: #fff; font-size: 42px">Save your time to innovate</h2>
    <p style="margin-left: 2rem; font-size: 18px; color: #8F949E; padding-right: 4rem; padding-left: 4rem">
      A comprehensive platform for automating the entire software development lifecycle, from code to production.
    </p>
    <p style="margin-left: 2rem; font-size: 18px; color: #8F949E; padding-right: 4rem; padding-left: 4rem">
      A user-friendly interface for managing and monitoring your infrastructure.
    </p>
    <p style="margin-left: 2rem; font-size: 18px; color: #8F949E; padding-right: 4rem; padding-left: 4rem">
      Reduce the time and effort required to manage and maintain the automation of the development and deployment process
    </p>
    <p style="margin-left: 2rem; font-size: 18px; color: #8F949E; padding-right: 4rem; padding-left: 4rem">
      Minimize downtime and ensure that your application is always available to users.
    </p>

  </div>
</div>
