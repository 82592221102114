import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TechnologyService} from "../../../application/services/technology.service";
import {takeUntil} from "rxjs";
import {Team} from "../../../team/models/team";
import {TechnologyDefinition} from "../../../application/models/technology-definition";

@Component({
  selector: 'app-create-application',
  templateUrl: './create-application.component.html',
  styleUrls: ['./create-application.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateApplicationComponent implements OnInit {
  currentAvailableVersion: any;
  applicationForm: FormGroup;
  teams = [
    {
      _id: 1,
      name: 'Nephos'
    },
  ]
  technologies = [
    {
      name: 'JAVA_SPRINGBOOT',
      availableVersions: ['17', '18'],
      friendlyName: 'Java springboot'
    },
    {
      name: 'VUEJS',
      availableVersions: [],
      friendlyName: 'Vue JS'
    }
  ];
  constructor(private fb: FormBuilder,
              private technologiesService: TechnologyService,
  ) {
  }

  ngOnInit(): void {
    this.applicationForm = this.fb.group({
      _id: [ null],
      name: [
         ''
      ],
      description: [
         ''
      ],
      technology: this.fb.group({
        name: [''],
        version: ['', []],
      }),
      teamId: [
        ''
      ],

    })
    this.applicationForm.get('technology')?.get('name')?.valueChanges
    .subscribe(
      {
        next: (name) => {
          this.applicationForm.get('technology')?.get('version')?.patchValue('', {emitEvent: false});
          this.currentAvailableVersion = this.technologies?.find(td => td?.name === name)?.availableVersions;
          if(this.currentAvailableVersion.length) {
            this.applicationForm.get('technology')?.get('version')?.patchValue(this.currentAvailableVersion[0], {emitEvent: false});
          }
        },
      }
    )
  }


}
