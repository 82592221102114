<div style="height: 100vh; background: #151b22;
    display: flex;
    align-items: center;
    justify-content: center;" *ngIf="(auth.isLoading$ | async) || isLoading; else loaded">
  <span class="loader"></span>
</div>

<ng-template #loaded>
  <div class="overlay" *ngIf="(sidebarFormService.toggleForm | async)?.state"></div>
  <nb-layout>
    <nb-layout-header fixed class="app--header">
      <a (click)="toggleLeftSideLeftSideNav()" *ngIf="auth.isAuthenticated$ | async">
        <nb-icon icon="menu-2-outline" style="color: var(--color-primary-500)" ></nb-icon>
      </a>
      <div style="margin-left: 4rem" *ngIf="!(auth.isAuthenticated$ | async)">
        <img style="width: 8rem;" src="assets/SVG/nephos-text-logo.svg" alt="nephos logo text" >
      </div>
      <div style="width: 100%; display: flex; justify-content: end" *ngIf="!(auth.isAuthenticated$ | async)">
        <button nbButton outline status="basic" (click)="login()">{{'auth.login' | translate}}</button>
      </div>

      <div style="display: flex; justify-content: end; width: 100%" *ngIf="auth.isAuthenticated$ | async">
        <div style="display: flex;width: 50%; justify-content: flex-end; margin-right: 6px">
          <nb-select [(ngModel)]="theme" (ngModelChange)="themeChange($event)">
            <nb-option [value]="'dark'">Dark</nb-option>
            <nb-option [value]="'default'">Light</nb-option>

          </nb-select>
        </div>
        <button nbButton outline status="basic"  (click)="logout()">{{'auth.logout' | translate}}</button>
      </div>

    </nb-layout-header>
    <nb-layout-column>
      <router-outlet ></router-outlet>
    </nb-layout-column>
    <nb-sidebar tag="left" responsive start *ngIf="auth.isAuthenticated$ | async">
      <div>
        <nb-menu tag="menu" [items]="menuItems"></nb-menu>
      </div>
    </nb-sidebar>
  </nb-layout>

</ng-template>

<nb-sidebar id="formSidebar" start right  fixed="true" tag="right" class="form--sidebar" state="collapsed" >
  <div class="side-bar--close">
    <button outline (click)="sidebarFormService.closeForm()" nbButton status="danger">{{'formSidebar.close' | translate | uppercase}}</button>
  </div>
  <ng-template adHost>
  </ng-template>
</nb-sidebar>

