import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Env} from "../../../environment/models/env";
import {Environment} from "../../../environment/models/environment";

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnvironmentComponent implements OnInit {
  envs: Env [] = [
    {
      name: 'dev',
      _id: '1',
      vaultKey: ''
    }
  ];
  environment: Environment;
  selectedTag = '1.0.0-rc2';
  constructor() {
    this.environment = new Environment({
      name: 'hello-world-dev',
      env: {
        _id: '1',
        name: 'dev'
      },
      application: {
        name: 'hello-world'
      },
      artifact: {
        tags: [
          '1.0.0-rc1',
          '1.0.0-rc2',
          '1.0.0-rc3',
        ]
      }
    })
  }

  ngOnInit(): void {
  }


  getPropByString(obj: any, propString: any): any {
    if(propString === 'name') {
      return obj.name
    }
    if(propString === 'env') {
      return `${this.envs?.find(c => c._id === this.environment?.env._id)?.name}`
    }
  }


  getImageTag() {
    return '1.0.0-rc2';
  }

}
