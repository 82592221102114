import {Application} from "../../application/models/application";
import {Env} from "./env";
import {Country} from "./country";
import {Artifact} from "./artifact";

export class EnvironmentCreationSteps {
  addManifests: Step;
  addManifestsRepoToArgocd: Step;
  createArgocdProject: Step;
  createArgocdApplication: Step;

  constructor(data: any) {
    data = data || {};
    this.addManifests = new Step(data?.addManifests);
    this.createArgocdApplication = new Step(data?.createArgocdApplication);
    this.createArgocdProject = new Step(data?.createArgocdProject)
    this.createArgocdApplication = new Step(data?.createArgocdApplication)
  }
}

export class Step {
  name: string;
  errors: string [];
  order: number;

  constructor(data?: any) {
    this.name = data?.name;
    this.errors = data?.errors;
    this.order = data?.order;
  }
}

export class Environment {

  _id?: string;
  manifestsSHA: string;
  env: Env;
  country: Country;
  values: any;
  application?: Application;
  environmentCreationSteps: EnvironmentCreationSteps;
  status: string;
  applicationId: string;
  name: string;
  argocdApplication: string
  artifact: Artifact;
  teamId: string;
  constructor(data?: any) {
    data = data || {};
    this._id = data._id;
    this.name = data.name;
    this.manifestsSHA = data.manifestsSHA;
    this.env = new Env(data.env);
    this.country = new Country(data.country);
    this.values = data.values;
    this.application = new Application(data.application);
    this.environmentCreationSteps = new EnvironmentCreationSteps(data.environmentCreationSteps);
    this.status = data.status;
    this.applicationId = data.applicationId;
    this.argocdApplication = data.argocdApplication;
    this.artifact = data.artifact;
    this.teamId = data.teamId;
  }
}
