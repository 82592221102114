import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-workflow-run',
  templateUrl: './workflow-run.component.html',
  styleUrls: ['./workflow-run.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowRunComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
