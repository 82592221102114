<div class="ci-container">
  <div class="tools">
    <div style="display: flex">
      <div class="remove"></div>
      <div class="hide"></div>
      <div class="full"></div>
    </div>
    <div class="file-name">
      ci.yml
    </div>
  </div>
  <div class="code-container">
    <div class="numbers">
      1<br>
      2<br>
      3<br>
      4<br>
      5<br>
      6<br>
      7<br>
      8<br>
      9<br>
      10<br>
      11<br>
      12<br>
      14<br>
      15<br>
      16<br>
    </div>
<pre>
<span class="keyword">on</span><span class="keyword">:</span> push
<span class="keyword">jobs</span><span class="keyword">:</span>
  <span class="keyword">node-build</span><span class="keyword">:</span>
    <span class="keyword">runs-on</span><span class="keyword">:</span> 'ubuntu-latest'
    <span class="keyword">steps</span><span class="keyword">:</span>
      - <span class="keyword">name</span><span class="keyword">:</span> ⬇️ Checkout
        <span class="keyword">uses</span><span class="keyword">:</span> actions/checkout@v3

      - <span class="keyword">name</span><span class="keyword">:</span> ⚙️ Set up Java
        <span class="keyword">uses</span><span class="keyword">:</span> actions/setup-java@v3
        <span class="keyword">with</span><span class="keyword">:</span>
          <span class="keyword"> java-version</span><span class="keyword">:</span> 18

      - <span class="keyword">name</span><span class="keyword">:</span> 🛠 Build app
        <span class="keyword">run</span><span class="keyword">:</span> mvn package
</pre>
  </div>
</div>
