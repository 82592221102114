export class Country {
  _id: string;
  name: string;
  code: string

  constructor(data?: any) {
    data = data || {};
    this._id = data._id;
    this.name = data.name;
    this.code = data.code;

  }

}
