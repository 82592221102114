<div class="spring-container">
  <div class="tools">
    <div class="img-container">
      <img src="assets/java_springboot.png" alt="spring" style="width: 4rem;">
    </div>
  </div>

  <div class="code-container">
    <div class="numbers">
      1<br>
      2<br>
      3<br>
      4<br>
      5<br>
      6<br>
    </div>
<pre>
  <span class="keyword">@SpringBootApplication</span>
  <span class="keyword">class</span> <span [innerHTML]="'HelloApplication {'"></span>
    <span class="keyword">public static</span> void <span class="keyword">main</span><span
      [innerHTML]="'(String[] args) {'"></span>
      <span>SpringApplication.</span><span class="keyword">run</span><span
      [innerHTML]="'(HelloApplication.class, args);'"></span>
    <span [innerHTML]="'}'"></span>
  <span [innerHTML]="'}'"></span>

</pre>
  </div>
</div>
