<nb-card>
  <nb-card-header>
      <span style="color: #41ACFF">CREATE APPLICATION</span>
  </nb-card-header>
  <nb-card-body class="items-col">
    <form [formGroup]="applicationForm">
      <input id="id" type="text" nbInput fullWidth formControlName="_id" [hidden]="true">
      <div class="form-group" style="display: flex; flex-direction: column" [hidden]="true">
        <label for="team" class="label" >{{'application.properties.team' | translate}}</label>
        <nb-select formControlName="teamId" id="team" >
          <nb-option *ngFor="let team of teams" [value]="team._id">{{team.name}}</nb-option>
        </nb-select>
        <div class="error--container"
             *ngIf="applicationForm?.get('teamId')?.invalid && (applicationForm?.get('teamId')?.dirty || applicationForm?.get('teamId')?.touched)">
              <span class="input--error"
                    *ngIf="applicationForm?.get('teamId')?.errors!['teamId']">{{ 'formErrors.required' | translate }}</span>
        </div>
      </div>
      <div class="form-group">
        <label for="name" class="label">{{'application.properties.name' | translate}}</label>
        <input id="name" type="text" nbInput fullWidth formControlName="name">
        <div class="error--container"
             *ngIf="applicationForm?.get('name')?.invalid && (applicationForm?.get('name')?.dirty || applicationForm?.get('name')?.touched)">
          <span class="input--error"
                *ngIf="applicationForm?.get('name')?.errors!['required']">{{ 'formErrors.required' | translate }}</span>
          <span class="input--error"
                *ngIf="applicationForm?.get('name')?.errors!['maxlength']">20 {{ 'formErrors.maxlength' | translate}}</span>
        </div>
      </div>
      <div class="form-group" >
        <label for="description" class="label">{{'application.properties.description' | translate}}</label>
        <input id="description" type="text" nbInput fullWidth  formControlName="description">
        <div class="error--container"
             *ngIf="applicationForm?.get('description')?.invalid && (applicationForm?.get('notes')?.dirty || applicationForm?.get('description')?.touched)">
              <span class="input--error"
                    *ngIf="applicationForm?.get('description')?.errors!['description']">{{ 'formErrors.required' | translate }}</span>
        </div>
      </div>
      <div formGroupName="technology" >
        <div  class="form-group" style="display: flex; flex-direction: column">
          <label for="technology-name" class="label">{{'application.properties.technology.name' | translate}}</label>
          <nb-select formControlName="name" id="technology-name" >
            <nb-option *ngFor="let technology of technologies" [value]="technology.name">{{technology?.friendlyName}}</nb-option>
          </nb-select>
          <div class="error--container"
               *ngIf="applicationForm?.get('name')?.invalid && (applicationForm?.get('name')?.dirty || applicationForm?.get('version')?.touched)">
              <span class="input--error"
                    *ngIf="applicationForm?.get('name')?.errors!['name']">{{ 'formErrors.required' | translate }}</span>
          </div>
        </div>
        <div class="form-group" style="display: flex; flex-direction: column" [hidden]="true">
          <label for="technology-version" class="label" >{{'application.properties.technology.version' | translate}}</label>
          <nb-select formControlName="version" id="technology-version" >
            <nb-option *ngFor="let version of currentAvailableVersion" [value]="version">{{version}}</nb-option>
          </nb-select>
          <div class="error--container"
               *ngIf="applicationForm?.get('version')?.invalid && (applicationForm?.get('version')?.dirty || applicationForm?.get('version')?.touched)">
              <span class="input--error"
                    *ngIf="applicationForm?.get('version')?.errors!['version']">{{ 'formErrors.required' | translate }}</span>
          </div>
        </div>
      </div>

    </form>
  </nb-card-body>
  <nb-card-footer>
    <div style="display: flex; justify-content: flex-end">
      <button [disabled]="!applicationForm.valid" nbButton status="primary">{{'formActions.create' | translate}}</button>
    </div>
  </nb-card-footer>
</nb-card>
