<nb-card>
  <div style="padding: 8px">
    <div style="font-size: 14px;
    font-weight: 700;">
      snapshot-workflow.yaml
    </div>
    <div style="font-size: 12px;
    color: #8b949e;">
      on: push
    </div>
  </div>
  <div style="display: flex; justify-content: center; align-items: center; height: 100%">
    <div class="steps-container">
      <div style="width: 18%;display: flex; justify-content: center">
        <nb-icon icon="checkmark-circle-2"  [status]="'success'"></nb-icon>
      </div>
      <div style="width: 70%; display: flex; justify-content: center">
        Build/Package Java
      </div>
      <div style="width: 20%; display: flex; justify-content: center; font-size: 12px;
    color: #8b949e;">
        1m30
      </div>
      <div class="right-external-round">
      </div>
      <div class="right-internal-round">
      </div>
      <div class="arrow">
      </div>
    </div>
    <div class="steps-container" style="margin-right: 0">
      <div style="width: 18%;display: flex; justify-content: center">
        <nb-icon icon="checkmark-circle-2"  [status]="'success'"></nb-icon>
      </div>
      <div style="width: 70%; display: flex; justify-content: center">
        Build/Push Docker
      </div>
      <div style="width: 20%; display: flex; justify-content: center; font-size: 12px;
    color: #8b949e">
        1m30
      </div>
      <div class="left-external-round">
      </div>
      <div class="left-internal-round">
      </div>
    </div>
  </div>
</nb-card>
