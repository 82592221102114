import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, map, Observable, Subject} from "rxjs";
import {Application} from "../models/application";
import {TechnologyDefinition} from "../models/technology-definition";

@Injectable({
  providedIn: 'root'
})
export class TechnologyService {
  private baseUrl = 'assets/technologies.json';

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<TechnologyDefinition []> {
    return this.http.get<TechnologyDefinition[]>(this.baseUrl).pipe(
      map(technologies => technologies.map(technology => new TechnologyDefinition(technology)))
    );
  }

}
