import { Injectable } from '@angular/core';
import {NbGlobalPhysicalPosition, NbToastrService} from "@nebular/theme";
import {TranslateService} from "@ngx-translate/core";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastrService: NbToastrService, private translateService: TranslateService) {
  }

  async error(message: string) {
    const title = await  lastValueFrom(this.translateService.get('notification.error'));
    this.toastrService
    .show(message, title, { status: 'danger', position: NbGlobalPhysicalPosition.TOP_RIGHT, duration: 5000 });
  }

  async success(message: string) {
    const title = await  lastValueFrom(this.translateService.get('notification.success'));
    this.toastrService
    .show(message, title, { status: 'success', position: NbGlobalPhysicalPosition.TOP_RIGHT, duration: 3000 });
  }

  async info(message: string) {
    const title = await  lastValueFrom(this.translateService.get('notification.info'));
    this.toastrService
    .show(message, title, { status: 'info', position: NbGlobalPhysicalPosition.TOP_RIGHT, duration: 1000000 });
  }

}
